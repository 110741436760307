@import 'mixins';

// Colors
$light-gray: #f4f5f7;

// Spacer
$spacer: 0.25rem;

// Header
$header-height: 76px;

// Navigation rail
$navigation-rail-width: 88px;

// Grid breakpoints
$breakpoint-xs: 305px;
$breakpoint-s: 600px;
$breakpoint-sm: 1050px;
$breakpoint-m: 1280px;
$breakpoint-l: 1920px;

/* Z-INDEX */
// Cards
$card-annotations-zIndex: 1;
$card-header-zIndex: 2;
$card-dropdown-zIndex: 3;

// Board
$board-zIndex: 0;
// Top bar
$topBar-zIndex: 1; // We need it on top of Board!
// Sidebar
$sidebar-zIndex: 10;

// snackbar
$snackbar-zIndex: 1000;
