@import 'global-variables.scss';
// UI styles
@import './margin.scss';
@import './padding.scss';
@import './font.scss';

:root {
  /* Missing tokens in cortex */
  --radii-8: 8px;
  --radii-4: 4px;
  --states-Primary-muted-inverse: #00a97a;
  --surfaces-space-use-a-surface: #fff;

  /* Dark Theme fixes */
  --gplus-input-placeholder: var(--color-gray-80, #737373);
  --gplus-base: white;
  --gplus-columns: #ececec;
}
@media (prefers-color-scheme: dark) {
  :root {
    --gplus-input-placeholder: var(--color-gray-60, #9a9a9a;);
    --gplus-base: black;
    --gplus-columns: #121212;
  }
}

* {
  box-sizing: border-box;
}

body {
  color: var(--color-text-default, #202020);
  font-family: var(--font-family-Special, Arial);
  font-size: 16px;
  margin: 0;
  padding: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overflow: hidden;
  background: var(--gplus-base, black);
}

/*
 OVERWRITES Cortex
 */
h1,
h2,
h3,
h4,
h5,
h6 {
  margin: 0;
}

/* 

SHARED STYLES 

*/
.right {
  float: right;
}

.absolute-centered {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}

.error-value {
  color: var(--color-text-error, #eb0000);
  gap: var(--spacing-4, 0.25rem);
  align-items: center;
}

// Highlight searched text
.highlight {
  background: var(--color-background-accent, #9e2ab5);
  color: var(--color-text-onAccent, #fff);
}
// Loader
.loader {
  width: 48px;
  height: 48px;
  border: 5px solid var(--color-background-primary, #00a97a);
  border-bottom-color: transparent;
  border-radius: 50%;
  display: inline-block;
  box-sizing: border-box;
  flex: 0 0 auto;
  animation: rotation 1s linear infinite;

  &.inline {
    width: 24px;
    height: 24px;
    border-width: 2px;
    border-bottom-color: transparent;
    &.white {
      border-top-color: white;
      border-left-color: white;
      border-right-color: white;
    }

    &.accent {
      border-top-color: var(--color-background-accent, #9e2ab5);
      border-left-color: var(--color-background-accent, #9e2ab5);
      border-right-color: var(--color-background-accent, #9e2ab5);
    }
  }
}

@keyframes rotation {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

// Common text
.label {
  color: var(--color-text-default, #202020);
}

// flex-auto
.flex-auto {
  flex: 0 0 auto;
}

// Custom list
.gplus-list {
  .item {
    padding: var(--spacing-16, 1rem) 0;

    display: grid;
    grid-template-columns: minmax(max-content, 40%) minmax(min-content, 60%);
    align-items: stretch;
    gap: var(--spacing-8, 0.5rem);

    border-bottom: 1px solid var(--color-border-lines, #dbdbdb);

    font-family: var(--font-family-Headline, Arial);
    font-size: var(--font-size-14, 0.875rem);
    line-height: var(--font-line-height-21, 1.3125rem);
    font-weight: 400;
    font-style: normal;
  }
  .label {
    color: var(--color-text-secondary, #737373);
  }
}

/* Missing typography defs*/
.b1 {
  font-family: var(--font-family-Headline, Arial);
  font-size: var(--font-size-16, 1rem);
  line-height: var(--font-line-height-24, 1.5rem);
  font-weight: 400;
  font-style: normal;
}

.b2 {
  font-family: var(--font-family-Headline, Arial);
  font-size: var(--font-size-14, 0.875rem);
  line-height: var(--font-line-height-21, 1.3125rem);
  font-weight: 400;
  font-style: normal;
}

.b3 {
  font-family: var(--font-family-Headline, Arial);
  font-size: var(--font-size-12, 0.75rem);
  line-height: var(--font-line-height-18, 1.125rem);
  font-weight: 400;
  font-style: normal;
}

h2 {
  color: var(--color-text-default, #202020);
  font-family: var(--typography-font, Arial);
  font-size: var(--font-size-24, 1.5rem);
  line-height: var(--font-line-height-36, 2.25rem);
  font-style: normal;
  font-weight: 700;
}

h3 {
  font-family: var(--font-family-Headline, Arial);
  color: var(--color-text-default, #202020);
  font-size: var(--font-size-20, 1.25rem);
  line-height: var(--font-line-height-30, 1.875rem);
  font-style: normal;
  font-weight: 700;
}

h4 {
  font-family: var(--font-family-Headline, Arial);
  color: var(--color-text-default, #202020);
  font-size: var(--font-size-18, 1.125rem);
  line-height: var(--font-line-height-20, 1.25rem);
  font-style: normal;
  font-weight: 700;
}

h5 {
  font-family: var(--font-family-Headline, Arial);
  color: var(--color-text-default, #202020);
  font-size: var(--font-size-16, 1rem);
  font-style: normal;
  font-weight: 700;
  line-height: var(--font-line-height-24, 1.5rem); /* 150% */
}

h6 {
  color: var(--color-text-default, #202020);
  font-family: var(--typography-font, Arial);
  font-size: var(--font-size-14, 0.875rem);
  font-style: normal;
  font-weight: 700;
  line-height: var(--font-line-height-21, 1.3125rem); /* 150% */
}
