$num-iterations: 20;

/* PADDING DEFINITIONS */
// Padding all sides
@for $i from 1 through $num-iterations {
  .p-#{$i} {
    padding: $spacer * $i;
  }
}

//  Padding on left and right
@for $i from 1 through $num-iterations {
  .px-#{$i} {
    padding-left: $spacer * $i;
    padding-right: $spacer * $i;
  }
}

// Padding on top and bottom
@for $i from 1 through $num-iterations {
  .py-#{$i} {
    padding-top: $spacer * $i;
    padding-bottom: $spacer * $i;
  }
}

// Padding Top
@for $i from 1 through $num-iterations {
  .pt-#{$i} {
    padding-top: $spacer * $i;
  }
}

// Padding Bottom
@for $i from 1 through $num-iterations {
  .pb-#{$i} {
    padding-bottom: $spacer * $i;
  }
}

// Padding right
@for $i from 1 through $num-iterations {
  .pr-#{$i} {
    padding-right: $spacer * $i;
  }
}

// Padding left
@for $i from 1 through $num-iterations {
  .pl-#{$i} {
    padding-left: $spacer * $i;
  }
}
