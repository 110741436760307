$num-iterations: 20;

/* MARGIN DEFINITIONS */
// Margin all sides
@for $i from 1 through $num-iterations {
  .m-#{$i} {
    margin: $spacer * $i;
  }
}

//  Margin on left and right
@for $i from 1 through $num-iterations {
  .mx-#{$i} {
    margin-left: $spacer * $i;
    margin-right: $spacer * $i;
  }
}

// Margin on top and bottom
@for $i from 1 through $num-iterations {
  .my-#{$i} {
    margin-top: $spacer * $i;
    margin-bottom: $spacer * $i;
  }
}

// Margin Top
@for $i from 1 through $num-iterations {
  .mt-#{$i} {
    margin-top: $spacer * $i;
  }
}

// Margin Bottom
@for $i from 1 through $num-iterations {
  .mb-#{$i} {
    margin-bottom: $spacer * $i;
  }
}

// Margin right
@for $i from 1 through $num-iterations {
  .mr-#{$i} {
    margin-right: $spacer * $i;
  }
}

// Margin left
@for $i from 1 through $num-iterations {
  .ml-#{$i} {
    margin-left: $spacer * $i;
  }
}
